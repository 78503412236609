<template>
  <div>
    <v-row>
      <v-col cols="12" sm="9">
        <v-row>
          <v-col id="process-overview" cols="12" sm="8">
            <v-divider></v-divider>
            <v-subheader style="font-size: 18px"> <v-icon color="grey" class="mr-2">account_tree</v-icon>
              Process Overview </v-subheader>
            <v-divider></v-divider>
            <v-expansion-panels v-model="panel" flat class="mb-0 pb-0">
              <v-expansion-panel v-for="(item, i) in filterShipmentProcess" :key="i"
                style="background: var(--v-component-base) !important">
                <v-expansion-panel-header>
                  <v-card flat style="background-color: var(--v-component-base) !important" color="component"
                    class="my-0 py-0">
                    <v-card-title style="font-size: 14px; color: grey" class="my-0 py-0">
                      <v-icon color="grey" class="mr-2">{{ item.icon }}</v-icon>
                      <span v-if="item.name == 'Voyage'">
                        {{ shipment.voyageInfo.vessel.name + ' ' +
                        shipment.voyageInfo.originalVoyage
                        }}
                      </span>
                      <span v-else>{{ item.name }}</span>
                      <v-spacer></v-spacer> <v-chip outlined small
                        v-if="item.name != 'Voyage' && getOverviewStatus(item.name)"
                        :color="getStatusColor(getOverviewStatus(item.name))">{{
                        getOverviewStatus(item.name) }}</v-chip>
                    </v-card-title>
                    <v-card-subtitle v-if="item.name == 'Documentation'" class="my-0 py-0">
                      <v-tooltip top v-for="doc in documentationSummary" :key="doc.status">
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" class="ml-2" outlined small :color="getStatusColor(doc.status)">
                            <v-icon small left>{{
                              getStatusIcon(doc.status)
                              }}</v-icon> {{ doc.status }} {{ doc.count }}
                          </v-chip>
                        </template>
                        <span>{{ doc.status }}</span>
                      </v-tooltip>
                    </v-card-subtitle>
                    <v-card-subtitle v-else-if="item.name == 'Voyage'" class="my-0 py-0">
                      <v-avatar size="24" v-if="shipment.voyageInfo.startLocode" class="mr-2">
                        <v-img contain
                          :src="`https://cdn.loglive.io/flags/4x3/${shipment.voyageInfo.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                      </v-avatar>
                      {{ shipment.voyageInfo.startLocation }} ({{ shipment.voyageInfo.startLocode }})
                      <v-icon small class="mx-1">arrow_forward</v-icon>
                      <v-avatar size="24" v-if="shipment.voyageInfo.endLocode" class="mx-2">
                        <v-img contain
                          :src="`https://cdn.loglive.io/flags/4x3/${shipment.voyageInfo.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                      </v-avatar> {{ shipment.voyageInfo.endLocation + `
                      (${shipment.voyageInfo.endLocode})` }}
                      <br>
                      <span>
                        {{ formatDate(shipment.etd) }} - {{
                        formatDate(shipment.eta)
                        }} - {{ shipment.voyageInfo.legs }} Leg(s) - {{
                        calculateDays(shipment.etd, shipment.eta) }}
                        Days
                      </span>
                    </v-card-subtitle>
                    <v-card-text v-if="item.name == 'Voyage' && shipment.voyageInfo.etd && shipment.voyageInfo.eta"
                      class="my-0 py-0">
                      <v-slider v-model="shipment.voyageInfo.progress" inverse-label
                        :color="shipment.voyageInfo.progress == 0 ? 'grey' : shipment.voyageInfo.progress < calculateDays(shipment.etd, shipment.eta) ? 'blue' : 'green'"
                        :label="calculatePercentage(shipment.voyageInfo.progress, calculateDays(shipment.etd, shipment.eta)) + '%'"
                        :thumb-size="24" readonly :min="0" :max="calculateDays(shipment.etd, shipment.eta)">
                      </v-slider>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="item.name == 'Documentation'">
                    <v-list dense>
                      <v-list-item v-for="document in summarisedDocuments" :key="document.id"
                        style="color: #ffffff !important" class="mt-1"
                        @click="document.count > 1 ? openDocumentSummary(document) : ''" :disabled="document.count == 1"
                        :class="document.count == 1 ? 'singleDoc' : ''">
                        <v-list-item-content class="text-wrap">
                          <v-list-item-title>
                            <b>{{ document.name }}</b>
                          </v-list-item-title>

                          <v-list-item-subtitle v-if="document.count === 1 && document.reference"
                            style="font-size: 11px">
                            Reference: {{ document.reference }}

                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="document.count === 1 && document.submittedDate"
                            style="font-size: 11px">
                            Submitted Date: {{ document.submittedDate }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="document.count === 1 &&document.completedDate "
                            style="font-size: 11px">
                            Completed Date:
                            <span>
                              {{ document.completedDate }}
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-row justify="center" class="ml-1">
                            <v-tooltip top v-for="status in document.statuses" :key="status.index">
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="mx-1 my-1" small pill outlined
                                  :color="getStatusColor(status.status)">
                                  <v-icon small left>{{
                                    getStatusIcon(status.status)
                                    }}</v-icon>
                                  {{ status.count }}
                                </v-chip>
                              </template>
                              {{ status.status }}
                            </v-tooltip>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-else-if="item.name == 'Voyage'">
                    <v-list dense class="my-0 py-0">
                      <v-card flat outlined v-for="leg in vesselVoyageLegs" :key="leg.id">
                        <v-card-title class="my-0 py-1" style="font-size: 14px;">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-avatar size="28" class="mr-2" v-on="on" :color="getLegColor(leg.status)">
                                {{ leg.leg }}
                              </v-avatar>
                            </template>
                            {{ leg.status ?? 'Not Started' }}
                          </v-tooltip>
                          <v-avatar size="24" v-if="leg.startLocode" class="mr-2">
                            <v-img contain
                              :src="`https://cdn.loglive.io/flags/4x3/${leg.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                          {{ leg.startLocation }} ({{ leg.startLocode }})
                          <v-icon small class="mx-1">arrow_forward</v-icon>
                          <v-avatar size="24" v-if="leg.endLocode" class="mx-2">
                            <v-img contain
                              :src="`https://cdn.loglive.io/flags/4x3/${leg.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                          </v-avatar> {{ leg.endLocation + ` (${leg.endLocode})` }}
                          <v-spacer></v-spacer>
                          <span v-if="leg.etd && leg.eta">
                            {{
                            calculateDays(leg.etd, leg.eta) }} Days
                          </span>

                        </v-card-title>
                        <v-card-text class="my-0 py-0">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon :color="leg.etd && leg.isATD ? 'success' : 'grey'">location_on</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title v-if="leg.etd">
                                    {{
                                    formatDateTime(leg.etd) }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else>No ETD from
                                    source</v-list-item-title>
                                  <v-list-item-subtitle v-if="leg.etd">
                                    {{ leg.isATD ? 'ATD' : 'ETD' }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-list-item>
                                <v-list-item-action>
                                  <v-icon :color="leg.eta && leg.isATA ? 'success' : 'grey'">where_to_vote</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title v-if="leg.eta"> {{
                                    formatDateTime(leg.eta) }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else>No ETA from
                                    source</v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ leg.eta && leg.isATA ? 'ATA' : 'ETA' }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="4" class="ma-0">
                              <v-list-item v-if="leg.modeOfTransport == 'VESSEL' && leg.vessel">

                                <v-list-item-content>
                                  <v-list-item-title style="font-size: 12px">
                                    <v-icon color="grey" small class="mr-2">directions_boat</v-icon>

                                    {{
                                    leg.vessel.name }}
                                  </v-list-item-title>
                                  <!-- <v-list-item-subtitle>
                                                                        {{ leg.vessel.type }}
                                                                    </v-list-item-subtitle> -->
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-else-if="leg.modeOfTransport == 'RAIL'">
                                <v-list-item-action>
                                  <v-icon color="grey">train</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Rail
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" class="my-0 py-0" v-if="leg.etd && leg.eta">
                              <v-slider readonly class="my-0 py-0" v-model="leg.progress" inverse-label
                                :color="leg.progress == 0 ? 'grey' : leg.progress < calculateDays(leg.etd, leg.eta) ? 'blue' : 'green'"
                                track-color="grey" :min="0" :max="calculateDays(leg.etd, leg.eta)"
                                :label="calculatePercentage(leg.progress, calculateDays(leg.etd, leg.eta)) + '%'">

                              </v-slider>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row>
              <v-col cols="12" sm="5">
                <v-list dense subheader>
                  <v-subheader style="font-size: 14px; color: grey">Events</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="shipmentTimeline.length ==0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"> No Events

                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <ul class="events" v-if="shipmentTimeline && shipmentTimeline.length > 0">
                    <li v-for="event in shipmentTimeline" :key="event.id" style="list-style-type: none;" class="my-3">
                      <v-avatar class="mb-1 mr-2" size="10"
                        :color="event.isActual ? 'success' : 'secondary'"></v-avatar>

                      <strong style="font-size: 14px">{{ formatDateTime(event.date) }}</strong>
                      <v-chip outlined style="border: none" small v-if="event.location">
                        <v-avatar size="20" left>
                          <v-img contain
                            :src="`https://cdn.loglive.io/flags/4x3/${event.location.substring(0, 2).toLowerCase()}.svg`"></v-img>
                        </v-avatar> {{ event.location }}
                      </v-chip>
                      <span class="ml-2" style="font-size: 14px">{{ event.description }}</span>
                      <span v-if="event.vesselName" class="ml-2">- {{ event.vesselName }} {{ event.voyageNo
                        }}</span>
                    </li>
                  </ul>
                </v-list>

              </v-col>
              <v-col cols="12" sm="7">
                <v-list dense subheader>
                  <v-subheader style="font-size: 14px; color: grey">Shipment Changes</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="shipmentChangeLogs.length ==0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"> No Changes

                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="change in shipmentChangeLogs" :key="change.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small color="secondary" class="mr-2 pb-1">event_repeat</v-icon> {{
                        formatChangeType(change.type) }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="change.locode">
                        <v-chip outlined style="border: none" small>
                          <v-avatar size="20" left>
                            <v-img contain
                              :src="`https://cdn.loglive.io/flags/4x3/${change.locode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                          </v-avatar> {{ change.locode }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 11px" v-else-if="change.vessel" class="text-wrap">
                        <v-icon small color="grey" class="mr-2">directions_boat</v-icon> {{ change.vessel.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 11px" v-else-if="change.changedOrganisation" class="text-wrap">
                        <v-icon small color="grey" class="mr-2">business</v-icon> Party: {{
                        change.changedOrganisation.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 11px" class="text-wrap"
                        v-if="change.responsibleOrganisation">
                        <v-icon small color="grey" class="mr-2">account_balance</v-icon> Responsible Party: {{
                        change.responsibleOrganisation.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 11px" class="text-wrap" v-if="change.consigneeProfile">
                        <v-icon small color="grey" class="mr-2">handshake</v-icon> Profile: {{
                        change.consigneeProfile.systemReference }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 11px" v-if="change.reason" class="text-wrap">
                        <v-icon small color="grey" class="mr-2">article</v-icon> {{ change.reason }}
                      </v-list-item-subtitle>

                    </v-list-item-content>
                    <v-list-item-action class="text-left">
                      <v-col cols="12" style="display: flex;flex-direction: column;flex-wrap: wrap;">
                        <v-chip outlined style="border: none" small v-if="change.user">
                          <v-avatar size="20" left :color="change.user.avatar?'white':'secondary'">
                            <v-img contain v-if="change.user.avatar" :src="change.user.avatar"></v-img>
                            <v-icon v-else color=white>person</v-icon>
                          </v-avatar> {{ change.user.firstname }} {{ change.user.surname}}
                        </v-chip>
                        <span class="ml-3 pl-3" style="font-size: 10px; color: grey"> {{
                          formatDateTime(change.createdAt) }}</span>
                      </v-col>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <!-- <v-card flat v-if="shipmentTimeline && shipmentTimeline.length > 0" class="mt-0 pt-0">
                            <v-card-title>
                                Transport Events
                            </v-card-title>
                            <v-card-text>
                                <v-timeline align-center dense>
                                    <v-timeline-item :color="event.isActual ? 'success' : 'secondary'" small
                                        v-for="event in shipmentTimeline" :key="event.id" :icon="event.isActual ? 'check' : ''"
                                        fill-dot>
                                        <v-row class="pt-1" align="center">
                                            <v-col cols="3">
                                                <strong>{{ formatDateTime(event.date) }}</strong> <br>
                                                <i v-if="!event.isActual">(Estimated)</i>
                                            </v-col>
                                            <v-col>
                                                <strong> <v-avatar size="24" v-if="event.location" class="mr-2">
                                                        <v-img contain
                                                            :src="`https://cdn.loglive.io/flags/4x3/${event.location.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                    </v-avatar> {{ event.location }}</strong>
                                                <div class="text-caption">
                                                    {{ event.description }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-timeline-item>
                                </v-timeline>
                            </v-card-text>
                        </v-card> -->
          </v-col>
          <!-- <v-col cols="12" sm="4">

                        
                    </v-col> -->
          <v-col cols="12" sm="4">
            <v-card id="shipment-summary" outlined v-if="!shipment.shipmentFile.breakBulkShipment" class="my-0 py-0">
              <v-card-title class="mb-0 pb-0">
                <v-icon left color="grey">widgets</v-icon>
                Shipment Summary
              </v-card-title>
              <v-card-subtitle class="my-0 py-0" style="font-size: 12px">
                <div v-if="!shipment.breakBulkShipment">
                  {{ bookings.length }} Containers - {{ uniquePallets }} Pallets
                </div>
                <div v-for="(summaryItem, index) in cartonSummary" :key="index">
                  <span> {{ summaryItem.count }} {{ summaryItem.packType }}</span>
                </div>
              </v-card-subtitle>
              <v-card-text class="my-0 py-0">
                <v-list class="my-0 py-0" dense>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>scale</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ containerNettWeight }} KG
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Total Nett Weight
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>scale</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ containerGrossWeight }} KG
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Total Gross Weight
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card id="shipment-overview-products" outlined v-if="shipmentProducts.length > 0 && !loadingProducts">
              <v-card-title class="my-0">
                <v-icon left color="grey">category</v-icon>
                Products <v-spacer></v-spacer><v-btn-toggle mandatory v-model="selectedProductFilter">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn value="product" v-on="on">
                        <v-icon>list</v-icon>
                      </v-btn>
                    </template>
                    View Product Summary
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn value="variety" v-on="on">
                        <v-icon>segment</v-icon>
                      </v-btn>
                    </template>
                    View Variety Summary
                  </v-tooltip>
                </v-btn-toggle>
              </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense class="my-0 py-0" style="max-height: 35vh; overflow-y: auto" v-loading="loadingProducts">
                  <v-list-item v-if="shipmentProducts.length == 0 && !loadingProducts">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No products listed.</span>
                    </v-list-item-content></v-list-item>
                  <v-list-item v-for="product in viewProductSummary" :key="product.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.product.name }}
                        <span v-if="product.product.hsCode">({{ product.product.hsCode.alternateCode
                          }})</span>
                      </v-list-item-title>
                      <div v-if="selectedProductFilter == 'product'">
                        <div v-if="binsAndCartons">
                          <v-list-item-subtitle style="font-size: 12px">
                            <v-icon small class="mr-1">widgets</v-icon> {{
                            calculateProductBins(product.productId) }} BINS
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px">
                            <v-icon small class="mr-1">widgets</v-icon> {{
                            calculateProductCartons(product.productId) }}
                            CARTONS
                          </v-list-item-subtitle>
                        </div>
                        <div v-else>
                          <v-list-item-subtitle style="font-size: 12px">
                            <v-icon small class="mr-1">widgets</v-icon> {{
                            getPackType() == 'BIN' ? calculateProductBins(product.productId) :
                            calculateProductCartons(product.productId) }}
                            {{ getPackType() + 'S' }}
                          </v-list-item-subtitle>
                        </div>
                      </div>
                      <div v-else>
                        <v-list-item-subtitle style="font-size: 12px">
                          <v-icon small class="mr-1">widgets</v-icon>{{
                          calculateVarietyProductCartons(
                          product.varietyProductId
                          )
                          }}
                          {{ getPackType() + 'S' }}
                        </v-list-item-subtitle>
                      </div>


                      <v-list-item-subtitle style="font-size: 12px" v-if="selectedProductFilter == 'product'">
                        <v-icon small class="mr-1">scale</v-icon>
                        {{ calculateProductNettWeight(product.productId) }}
                        Kg Nett Weight
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px" v-else>
                        <v-icon small class="mr-1">scale</v-icon>
                        {{
                        calculateVarietyProductNettWeight(
                        product.varietyProductId
                        )
                        }}
                        Kg Nett Weight
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px" v-if="selectedProductFilter == 'product'">
                        <v-icon small class="mr-1">scale</v-icon>
                        {{ calculateProductGrossWeight(product.productId) }}
                        Kg Gross Weight
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px" v-else>
                        <v-icon small class="mr-1">scale</v-icon>
                        {{
                        calculateVarietyProductGrossWeight(
                        product.varietyProductId
                        )
                        }}
                        Kg Gross Weight
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="selectedProductFilter == 'product' &&
                                                    product.productTree &&
                                                    product.productTree.length > 0
                                                    ">
                        <v-breadcrumbs style="font-size: 12px" class="ma-0 pa-0" :items="product.productTree">
                          <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :disabled="true">
                              <span style="font-size: 12px">{{
                                item.hsCode
                                ? item.name +
                                ` (${item.hsCode.alternateCode})`
                                : item.name
                                }}</span>
                            </v-breadcrumbs-item>
                          </template>
                          <template v-slot:divider>
                            <v-icon small>chevron_right</v-icon>
                          </template>
                        </v-breadcrumbs>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-else-if="selectedProductFilter == 'variety' &&
                                                    product.varietyTree &&
                                                    product.varietyTree.length > 0
                                                    ">
                        <v-breadcrumbs style="font-size: 12px" class="ma-0 pa-0" :items="product.varietyTree">
                          <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :disabled="true">
                              <span style="font-size: 12px">{{
                                item.hsCode
                                ? item.name +
                                ` (${item.hsCode.alternateCode})`
                                : item.name
                                }}</span>
                            </v-breadcrumbs-item>
                          </template>
                          <template v-slot:divider>
                            <v-icon small>chevron_right</v-icon>
                          </template>
                        </v-breadcrumbs>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card id="shipment-overview-products" outlined v-else-if="shipmentProducts.length == 0">
              <v-card-title class="my-0">
                <v-icon left color="grey">category</v-icon>
                Products
              </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense class="my-0 py-0" style="max-height: 35vh; overflow-y: auto" v-loading="loadingProducts">
                  <v-list-item v-if="containerProductSummary.length == 0 && !loadingProducts">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No products listed.</span>
                    </v-list-item-content></v-list-item>
                  <v-list-item v-for="product in containerProductSummary" :key="product.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.product.name }}
                        <span v-if="product.product.hsCode">({{ product.product.hsCode.alternateCode
                          }})</span>
                      </v-list-item-title>

                      <v-list-item-subtitle style="font-size: 12px">
                        <v-icon x-small color="grey" class="mr-1">scale</v-icon>
                        {{ product.nettWeight }}
                        Kg Nett Weight
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="font-size: 12px">
                        <v-icon x-small color="grey" class="mr-1">scale</v-icon>
                        {{ product.grossWeight }}
                        Kg Gross Weight
                      </v-list-item-subtitle>
                      <!-- <v-list-item-subtitle style="font-size: 12px"
                                                v-if="selectedProductFilter == 'product'">
                                                <v-icon small class="mr-1">scale</v-icon>
                                                {{ calculateProductGrossWeight(product.productId) }}
                                                Kg Gross Weight
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 12px" v-else>
                                                <v-icon small class="mr-1">scale</v-icon>
                                                {{
                                                    calculateVarietyProductGrossWeight(
                                                        product.varietyProductId
                                                    )
                                                }}
                                                Kg Gross Weight
                                            </v-list-item-subtitle> -->

                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

          </v-col>


          <!-- <v-col cols="12">
                     
                    </v-col> -->
        </v-row>
      </v-col>

      <v-col cols="12" sm="3">

        <v-card outlined :loading="loadingComments" id="shipment-overview-comments">
          <v-card-title class="my-0">
            <v-icon left color="grey">question_answer</v-icon>
            Comments
          </v-card-title>
          <v-card-text class="my-0 py-0">
            <v-list ref="commentList" dense style="height: 55vh; overflow-y: auto">
              <v-list-item v-if="comments.length == 0 && documentComments.length">
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey"> No comments. </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="(comment, index) in comments" :key="comment.id" :id="'comment_' + index"
                class="py-0 my-1" :disabled="comment.userId != $store.state.user.id"
                @click="openCommentsModal(comment)">
                <v-list-item-avatar v-if="comment.userId != $store.state.user.id">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                        class="mt-0 pt-0">
                        <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
                          $Format.avatarAbbreviation(comment.user.firstname + " " + comment.user.surname)
                          }}</span>
                        <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px">{{ comment.user.firstname }}
                      {{ comment.user.surname}}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content v-if="comment.userId == $store.state.user.id && comment.isDeleted == false">

                  <div style="display: flex;flex-direction: row;justify-content: start;">

                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">

                        <v-btn v-on="{ ...tooltip }" text @click="openShipmentDialog(comment)"><v-icon
                            color="blue">edit</v-icon></v-btn>

                      </template>
                      <span>Edit Comment</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">

                        <v-btn :loading="loadingShipmentDeleteComment" v-on="{ ...tooltip }" text
                          @click="deleteShipmentComment(comment.id)">
                          <v-icon color="red">delete</v-icon>

                        </v-btn>

                      </template>
                      <span>Remove Comment</span>
                    </v-tooltip>
                  </div>
                </v-list-item-content>

                <v-list-item-content v-if="comment.userId == $store.state.user.id && comment.isDeleted == true">
                  <div></div>

                </v-list-item-content>



                <v-list-item-content :class="comment.userId == $store.state.user.id
                                        ? 'text-right'
                                        : 'text-left'
                                    ">
                  <v-list-item-title class="text-wrap" v-if="comment.isDeleted == true"
                    style="text-decoration: line-through;color: gray">
                    {{ comment.comment }}
                  </v-list-item-title>
                  <v-list-item-title class="text-wrap" v-else>
                    {{ comment.comment}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <i style="font-size: 12px; color: grey">{{ 'Shipment' }} - {{
                      formatTime(comment.createdAt) }}</i>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
             
            </v-list>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="my-0 py-0">
            <v-text-field :loading="savingComment" :disabled="savingComment" style="width: 100%" class="my-0 py-0"
              v-model="comment" outlined dense clearable placeholder="Comment" type="text" append-outer-icon="send"
              v-on:keyup.enter="createComment" @click:append-outer="createComment">
            </v-text-field>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>



    <v-dialog v-model="editButton" max-width="500px">



      <v-card>

        <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: baseline;">
          <v-card-title>Edit Comment</v-card-title>
          <v-card-actions>
            <v-btn :loading="loadingShipmentEditComment" text
              @click="updateContainerComment(newEditedComment.id)"><v-icon color="blue">save</v-icon></v-btn>
            <v-btn text @click="closeShipmentEditDialog()">X</v-btn>

          </v-card-actions>
        </div>
        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
          <v-textarea v-model="newEditedComment.comment" style="width: 80%; contain: content; border-radius: 20px;"
            outlined rows="4">
          </v-textarea>
        </div>

      </v-card>
    </v-dialog>




















    <v-dialog v-model="infoStart" max-width="60vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          Welcome to your selected File or Shipment.
        </v-card-title>
        <v-card-text>
          <span>
            <p>The purpose of this section is to give the user summary overviews as well as detailed content of
              the whole shipment.
              We have organized the contents of the Shipment into the following primary categories:</p>
            <p><b>Shipment Header:</b>
              <br>This section displays key parties, including the Shipper, Forwarder,
              and Receiver. It also indicates the route, from the Port of Load to the Port of Destination,
              along with the specific Vessel/Voyage and Shipping Line. You'll find the latest Expected
              Departure and Arrival Dates,
              and these will be updated to Actual Dates when available.
            </p>
            <p><b>Shipment Overview:</b>
              <br>In this section, you'll find a comprehensive Process Overview.
              It lists the required documentation for this shipment, along with the completion status of each
              document.
              <br>
              <br>
              You'll also see a Shipment Summary, detailing the total count of Containers, Pallets, Cartons,
              Nett Weight, and Gross Weight.
              This summary is grouped by product and can be further grouped by variety, based on user
              preference.
              <br>
              <br>
              Additionally, there's a Comments section. Here, users can leave collaborative comments,
              connecting individuals from the
              same company or any other company associated with this shipment.
            </p>
            <p><b>Containers:</b>
              <br>In this section, you will find a comprehensive list of the various containers included in
              this shipment.
              This overview highlights key container details for your convenience. Clicking on a container
              will
              provide you with an in-depth breakdown, including:
            <ul>
              <li>Detailed Product Breakdown: Comprehensive view of the products contained within the
                container, including specific quantities and details.</li>
              <li>Telemetry Device Data; Access available data and readings from the telemetry device
                associated with the container.</li>
              <li>Content: Detail info lines per pallet of the container.</li>
            </ul>
            </p>
            <p><b>Product Overview:</b>
              <br>This section provides a comprehensive breakdown of all products across all containers within
              the shipment. It offers a detailed view of the products
              contained in each container, including precise quantities and specific details.
            </p>
            <p><b>Uploads:</b>
              <br>This section serves as a central hub for managing, storing, and sharing copies of documents,
              both in draft and final forms. Documents can be seamlessly passed through the system for
              approval.
              Once confirmed/approved, final versions are uploaded and shared with relevant parties involved
              in the shipment process.
              This streamlined process ensures efficient document handling and facilitates collaboration among
              stakeholders.
            </p>
            <p><b>Shipment Profile:</b>
              <br>In this section, you'll find a comprehensive overview of all the companies participating in
              the shipment,
              along with their confirmed roles. Teams and users from relevant companies can subscribe to
              receive system
              notifications for shipment updates. The distribution list can also be updated to notify users
              via email,
              providing direct links to the system for convenient access to any shipment-related updates. This
              feature
              enhances collaboration and keeps stakeholders informed throughout the shipment process.
            </p>

          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="documentSummary" width="50vw">
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title v-if="docsToView[0]?.locationDocument?.name">
            {{ docsToView[0].locationDocument.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="documentHeaders" :items="docsToView" hide-default-footer>
            <template v-slot:[`item.status`]="{ item }">
              <v-icon :color="getStatusColor(item.status)">{{ getStatusIcon(item.status) }}</v-icon>
              <span style="font-size: 12px">{{ item.status }}</span>
            </template>
            <template v-slot:[`item.containerNo`]="{ item }">
              <div v-if="item.shipmentDocumentTypeContainers.length > 0">
                <div v-for="container in item.shipmentDocumentTypeContainers" :key="container.id">
                  <span class="d-flex justify-center align-center">
                    <div class="dot mr-2"></div>{{ container.bookingContainer ? container.bookingContainer.containerNo :
                    ' ' }}
                  </span>
                </div>
              </div>
              <div v-else-if="item.bookingContainer">
                <span class="d-flex justify-center align-center">
                  <div class="dot mr-2"></div>{{ item.bookingContainer.containerNo }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh"
      style="position: absolute; z-index: 5; right: 3vw; top: 3vh">
      <v-icon>help</v-icon>
    </v-btn>


  </div>
</template>
<script>
import dateFormat from "dateformat";
import * as moment from "moment";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/shipmentOverviewSteps";

export default {
  props: [
    "shipment",
    "bookings",
    "documents",
    "shipmentProducts",
    "loadingProducts",
    "relationships",
    "shipmentChangeLogs",
    "customerId",
  ],
  components: {
    // ShipmentChange,
  },
  data: () => ({
    steps: null,
    selectedComment: "",
    comment: null,
    documentSummary: false,
    editButton:false,
    loadingShipmentEditComment:false,
    loadingShipmentDeleteComment:false,
    newEditedComment: "",
    docsToView: [],
    isCommentsModalOpen: false,
    comments: [],
    documentComments: [],
    documentHeaders: [
      {
        text: "Containers",
        align: "center",
        sortable: false,
        value: "containerNo",
      },
      {
        text: "Reference",
        align: "center",
        sortable: false,
        value: "reference",
      },
      {
        text: "Submitted Date",
        align: "center",
        sortable: false,
        value: "submittedDue",
      },
      {
        text: "Completed Date",
        align: "center",
        sortable: false,
        value: "completedDue",
      },
      {
        text: "Status",
        align: "center",
        sortable: false,
        value: "status",
      },
    ],
    loadingComments: false,
    panel: [0],
    savingComment: false,
    shipmentChangeModal: false,
    shipmentChangeType: null,
    selectedProductFilter: "product",
    shipmentProcesses: [
      { name: "Documentation", icon: "description" },
      { name: "Voyage", icon: "directions_boat" },
    ],
    shipmentTimeline: [],
    voyageProgress: 5,
    infoStart: false,
  }),
  watch: {
    "shipment.id": {
      immediate: true,
      handler(val) {
        this.getComments(val);
        this.getShipmentContainerEvents(val);
      },
    },
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
  },
  computed: {
    cartonSummary() {
      let palletPacktypes = [...new Set(this.pallets.map((x) => x.packType))];
      let result = [];
      for (let i = 0; i < palletPacktypes.length; i++) {
        let pallets = this.pallets.filter(
          (x) => x.packType == palletPacktypes[i]
        );
        let cartons = pallets
          .map((x) => parseInt(x.noCartons))
          .reduce((a, b) => a + b, 0);
        result.push({
          packType: palletPacktypes[i] ?? "CARTON" + "(S)",
          count: cartons,
        });
      }
      return result;
    },
    binsAndCartons() {
      let palletPacktypes = this.pallets.map((x) => x.packType);
      if (
        palletPacktypes.includes("BIN") &&
        palletPacktypes.includes("CARTON")
      ) {
        return true;
      } else {
        return false;
      }
    },
    containerNettWeight() {
      let pallets = this.pallets;
      if (pallets.length > 0) {
        return pallets
          .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        let products = [].concat.apply(
          [],
          this.bookings.map((x) =>
            x.containerProducts.filter((y) => y.nettWeight)
          )
        );
        return products
          .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    containerGrossWeight() {
      let pallets = this.pallets;
      if (pallets.length > 0) {
        return pallets
          .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        let products = [].concat.apply(
          [],
          this.bookings.map((x) =>
            x.containerProducts.filter((y) => y.grossWeight)
          )
        );
        return products
          .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    documentationSummary() {
      let uniqueStatuses = [...new Set(this.documents.map((x) => x.status))];
      uniqueStatuses = uniqueStatuses.map((x) => ({
        status: x,
        count: this.documents.filter((y) => y.status == x).length,
      }));
      return uniqueStatuses;
    },
    filterShipmentProcess() {
      let result = this.shipmentProcesses;
      if (!this.shipment.voyageInfo) {
        result = result.filter((x) => x.name != "Voyage");
      }
      return result;
    },
    pallets() {
      let pallets = [].concat.apply(
        [],
        this.bookings.map((x) => x.containerPallets)
      );
      return pallets;
    },
    uniquePallets() {
      let pallets = [].concat.apply(
        [],
        this.bookings.map((x) => x.containerPallets)
      );
      if (pallets.length > 0) {
        return [...new Set(pallets.map((x) => x.sscc))].length;
      } else {
        let products = [].concat.apply(
          [],
          this.bookings.map((x) => x.containerProducts.filter((y) => y.pallets))
        );
        return products.map((x) => x.pallets).reduce((a, b) => a + b, 0);
      }
    },
    summarisedDocuments() {
      let result = [];
      // let statuses = ["Pending", "In Progress", "Submitted", "Complete"];
      let statuses = [...new Set(this.documents.map((x) => x.status))];

      let uniqueDocuments = [
        ...new Set(
          this.documents
            .filter((x) => statuses.includes(x.status))
            .map((x) => x.locationDocumentId)
        ),
      ];
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let document = this.documents.find(
          (x) => x.locationDocumentId == uniqueDocuments[i]
        );
        let obj = {
          id: document.id,
          name: document.locationDocument.name,
          count: this.documents.filter(
            (x) => x.locationDocumentId == uniqueDocuments[i]
          ).length,
          completedDue: document.completedDue,
          completedDate: document.completedDate,
          submittedDue: document.submittedDue,
          submittedDate: document.submittedDate,
          reference: document.reference,
          statuses: statuses
            .map((x, index) => ({
              index: index,
              status: x,
              count: this.documents.filter(
                (y) =>
                  y.locationDocumentId == uniqueDocuments[i] && y.status == x
              ).length,
            }))
            .filter((x) => x.count > 0),
        };
        result.push(obj);
      }
      return result;
    },
    vesselVoyageLegs() {
      return this.shipment.voyageInfo.vesselVoyageLegs;
    },
    containerProductSummary() {
      let products = [].concat.apply(
        [],
        this.bookings.map((x) => x.containerProducts)
      );
      products.forEach((x) => {
        x.hash = x.productId + x.hazardous + x.industrial + x.organic;
      });
      let uniqueHash = [...new Set(products.map((x) => x.hash))];
      let result = [];
      for (let i = 0; i < uniqueHash.length; i++) {
        let filtered = products.filter((x) => x.hash == uniqueHash[i]);
        let uniqueQuantities = [
          ...new Set(filtered.map((x) => x.quantityType)),
        ];
        let detail = filtered[0];
        let obj = {
          id: i + 1,
          product: detail.product,
          hasCode: detail.hsCode,
          organic: detail.organic,
          industrial: detail.industrial,
          hazardous: detail.hazardous,
          grossWeight: filtered
            .filter((x) => x.grossWeight)
            .map((x) => (parseFloat(x.grossWeight) * 100) / 100)
            .reduce((a, b) => a + b, 0),
          nettWeight: filtered
            .filter((x) => x.nettWeight)
            .map((x) => (parseFloat(x.nettWeight) * 100) / 100)
            .reduce((a, b) => a + b, 0),
        };
        result.push(obj);
      }
      return result;
    },
    viewProductSummary() {
      let result = [];
      if (this.selectedProductFilter == "product") {
        let uniqueProducts = [
          ...new Set(this.shipmentProducts.map((x) => x.productId)),
        ];
        uniqueProducts.forEach((x) => {
          let product = this.shipmentProducts.find((y) => y.productId == x);
          result.push(product);
        });
      } else {
        let uniqueProducts = [
          ...new Set(this.shipmentProducts.map((x) => x.varietyProductId)),
        ];
        uniqueProducts.forEach((x) => {
          let product = this.shipmentProducts.find(
            (y) => y.varietyProductId == x
          );
          result.push(product);
        });
      }
      return result;
    },
  },
  async created() {
    await this.getSteps();
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'ShipmentSummary/ShipmentOverview/Steps',
        });
        this.steps = steps.steps
        },

    openShipmentDialog(comment) {
      this.loadingShipmentEditComment = false;
      this.newEditedComment = comment
      console.log(comment)
      this.editButton = true;
   
    },
    closeShipmentEditDialog() {
      this.editButton = false;
     
    },

    async deleteShipmentComment(id){
    this.loadingShipmentDeleteComment = true;
    console.log(id);
     let result =  await this.$API.deleteShipmentComment({
          id: id,
          isDeleted: true,
          isActive: false,
          shipmentId: this.shipment.id
        });
        if(result){
          this.loadingShipmentDeleteComment = false;
          this.containerComments = result
          this.getComments(this.shipment.id);
        }
    },
    async updateContainerComment(id){
      this.loadingShipmentEditComment = true;
     let result =  await this.$API.updateShipmentComment({
          id: id,
          isDeleted: true,
          isActive: false,
          shipmentId: this.shipment.id,
          userId : this.newEditedComment.userId,
          comment:this.newEditedComment.comment
        });
        if(result){
          this.containerComments = result;
          this.closeShipmentEditDialog();
          this.getComments(this.shipment.id);
          this.loadingShipmentEditComment = false;
          
        }
    },
    openDocumentSummary(doc) {
      this.docsToView = [];
      this.docsToView = this.documents.filter(
        (x) => x.locationDocument.name == doc.name
      );
      this.documentSummary = true;
    },
    async deleteComment(comment) {
      console.log(comment);
      let id = comment.id;
      let result;
      if (
        comment.commentType == "Invoice" ||
        comment.commentType == "General" ||
        comment.commentType == "Query"
      ) {
        result = await this.$API.deleteShipmentDocumentComments(id);
      } else {
        result = await this.$API.deleteShipmentComment(id);
      }
      console.log(result);
      if (result.status == 200) {
        this.$message.success("Comment deleted successfully.");
        this.getComments(this.shipment.id);
        this.closeCommentsModal();
      }
    },
    async updateComment(comment) {
      console.log("comment.type", comment.commentType);
      let result;
      if (
        comment.commentType == "Invoice" ||
        comment.commentType == "General" ||
        comment.commentType == "Query"
      ) {
        result = await this.$API.updateShipmentDocumentComments(comment);
      } else {
        result = await this.$API.updateShipmentComment(comment);
      }

      if (result.status == 200) {
        this.$message.success("Comment updated successfully.");
        this.getComments(this.shipment.id);
        this.closeCommentsModal();
      }
    },
    openCommentsModal(comment) {
      this.selectedComment = comment;
      this.isCommentsModalOpen = true;
    },
    closeCommentsModal() {
      this.isCommentsModalOpen = false;
      this.selectedComment = "";
    },
    async getComments(id) {
      this.loadingComments = true;
      this.comments = await this.$API.getShipmentComments(id);
      this.documentComments = await this.$API.getShipmentDocumentComments(id);
      this.scrollToEnd();
      this.loadingComments = false;
    },
    async createComment() {
      if (this.comment != "") {
        this.savingComment = true;
        let obj = {
          comment: this.comment,
          userId: this.$store.state.user.id,
          shipmentId: this.shipment.id,
          isEdited: false,
        };
        let newComment = await this.$API.createShipmentComment(obj);
        this.comments.push(newComment);
        this.comment = "";
        this.savingComment = false;
        this.scrollToEnd();
      } else {
        this.$message.error("Can't create empty comment.");
      }
    },
    calculateDays(start, end) {
      start = moment(start);
      end = moment(end);
      let difference = end.diff(start, "days");
      let count = difference;
      return count + 1;
    },
    calculatePercentage(start, end) {
      let result = parseInt(start) / parseInt(end);
      return Math.round(result * 100);
    },
    calculateProductCartons(productId) {
      let pallets = this.pallets.filter(
        (x) => x.productId == productId && x.packType == "CARTON"
      );
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductBins(productId) {
      let bins = this.pallets.filter(
        (x) => x.productId == productId && x.packType == "BIN"
      );
      return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductNettWeight(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.nettWeight), 0)
        .toFixed(2);
    },
    calculateVarietyProductNettWeight(varietyId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == varietyId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.nettWeight), 0)
        .toFixed(2);
    },
    calculateProductGrossWeight(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.grossWeight), 0)
        .toFixed(2);
    },
    calculateVarietyProductCartons(productId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == productId);
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateVarietyProductGrossWeight(productId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == productId);
      return pallets
        .reduce((a, b) => a + parseFloat(b.grossWeight), 0)
        .toFixed(2);
    },
    formatChangeType(type) {
      switch (type) {
        case "CHANGE_SHIPPER":
          return "Change of Shipper";
        case "ORIGINAL_SHIPPER":
          return "Original Shipper";
        case "CHANGE_ON_BEHALF_SHIPPER":
          return "Change of On Behalf Shipper";
        case "ORIGINAL_ON_BEHALF_SHIPPER":
          return "Original On Behalf Shipper";
        case "ORIGINAL_FORWARDER":
          return "Original Forwarder";
        case "CHANGE_FORWARDER":
          return "Change of Forwarder";
        case "ORIGINAL_ON_BEHALF_FORWARDER":
          return "Original On Behalf Forwarder";
        case "CHANGE_ON_BEHALF_FORWARDER":
          return "Change of On Behalf Forwarder";
        case "ORIGINAL_BUYER":
          return "Original Buyer";
        case "CHANGE_BUYER":
          return "Change of Buyer";
        case "ORIGINAL_ON_BEHALF_BUYER":
          return "Original On Behalf Buyer";
        case "CHANGE_ON_BEHALF_BUYER":
          return "Change of On Behalf Buyer";
          case "CHANGE_SHIPPING_LINE":
          return "Change of Shipping Line";
        case "CHANGE_CONSIGNEE":
          return "Change of Consignee";
        case "ORIGINAL_CONSIGNEE":
          return "Original Consignee";
        case "CHANGE_ON_BEHALF_CONSIGNEE":
          return "Change of On Behalf Consignee";
        case "ORIGINAL_ON_BEHALF_CONSIGNEE":
          return "Original On Behalf Consignee";
        case "ORIGINAL_FIRST_NOTIFY":
          return "Original First Notify";
        case "CHANGE_FIRST_NOTIFY":
          return "Change of First Notify";
        case "ORIGINAL_SECOND_NOTIFY":
          return "Original Second Notify";
        case "CHANGE_SECOND_NOTIFY":
          return "Change of Second Notify";
        case "ORIGINAL_COURIER_PARTY":
          return "Original Courier Party";
        case "CHANGE_COURIER_PARTY":
          return "Change of Courier Party";
        case "CHANGE_DESTINATION":
          return "Change Destination";
        case "ORIGINAL_DESTINATION":
          return "Original Destination";
        case "CHANGE_POD":
          return "Change POD";
        case "ORIGINAL_POD":
          return "Original POD";
        case "CHANGE_VESSEL":
          return "Change Vessel";
        case "ORIGINAL_VESSEL":
          return "Original Vessel";
        case "REMOVE_SHIPPER":
          return "Remove Shipper";
        case "REMOVE_ON_BEHALF_SHIPPER":
          return "Remove On Behalf Shipper";
        case "REMOVE_FORWARDER":
          return "Remove Forwarder";
        case "REMOVE_ON_BEHALF_FORWARDER":
          return "Remove On Behalf Forwarder";
        case "REMOVE_BUYER":
          return "Remove Buyer";
        case "REMOVE_ON_BEHALF_BUYER":
          return "Remove On Behalf Buyer";
        case "REMOVE_CONSIGNEE":
          return "Remove Consignee";
        case "REMOVE_ON_BEHALF_CONSIGNEE":
          return "Remove On Behalf Consignee";
        case "REMOVE_FIRST_NOTIFY":
          return "Remove First Notify";
        case "REMOVE_SECOND_NOTIFY":
          return "Remove Second Notify";
        case "REMOVE_COURIER_PARTY":
          return "Remove Courier Party";
        case "ORIGINAL_PROFILE":
          return "Original Profile";
        case "CHANGE_PROFILE":
          return "Change Profile";
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    getOverviewStatus(status) {
      switch (status) {
        case "Documentation":
          return this.shipment.documentationStatus;
        case "Voyage":
          return this.shipment.voyageInfo
            ? this.shipment.voyageInfo.status
            : null;
        default:
          return "status";
      }
    },
    getLegColor(status) {
      switch (status) {
        case "Complete":
          return "success";
        case "In Transit":
          return "blue";
        case "In Holding":
          return "teal";
        default:
          return "grey";
      }
    },
    getPackType() {
      let type = this.pallets.find((x) => x.packType);
      return type ? type.packType : "CARTONS";
    },
    async getShipmentContainerEvents(id) {
      this.shipmentTimeline = await this.$API.getShipmentContainerEvents(id);
      console.log(this.shipmentTimeline);
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "orange";
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "cancelled":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "delivered":
          return "green";
        case "in progress":
          return "indigo";
        case "created":
          return "blue";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "draft":
          return "orange";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "rejected":
          return "red";
        case "query":
          return "purple";
        case "in transnit":
          return "orange";
        case "failed":
          return "red";
        case "new":
          return "amber";
        case "error":
          return "red";
        case "not created":
          return "red";
        case "in transit":
          return "blue";
        case "validation failed":
          return "red";
        case "inspection failed":
          return "red";
        case "inspection approved":
          return "success";
        case status.toLowerCase().includes("pending"):
          return "orange";
        case status.toLowerCase().includes("approved"):
          return "green";
        case status.toLowerCase().includes("failed"):
          return "red";
        case status.toLowerCase().includes("revoked"):
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "warning";
        case "not required":
          return "close";
        case "cancelled":
          return "cancel";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "created":
          return "mark_email_read";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "delivered":
          return "task_alt";
        case "file generated":
          return "task";
        case "new":
          return "star";
        case "error":
          return "cancel";
        case "failed":
          return "cancel";
        case "rejected":
          return "cancel";
        case "not created":
          return "error";
        case "approved":
          return "fact_check";
        case "validation failed":
          return "error";
        case "inspection failed":
          return "error";
        case "inspection approved":
          return "fact_check";
        case "in transit":
          return "local_shipping";
        case status.toLowerCase().includes("pending"):
          return "pending";
        case status.toLowerCase().includes("approved"):
          return "fact_check";
        case status.toLowerCase().includes("failed"):
          return "error";
        case status.toLowerCase().includes("revoked"):
          return "error";
        default:
          return "info";
      }
    },
    formatTime(time) {
      let result = null;
      if (time) {
        result = dateFormat(new Date(time), "HH:MM dd mmmm yyyy");
      }
      return result;
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var list = this.$refs.commentList;
        list.$el.scrollTop = list.$el.scrollHeight;
      });
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
  },
};
</script>

<style>
.singleDoc :is(.theme--dark .v-list-item--disabled, ) {
  color: #ffffff !important;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #ffffff;
  border-radius: 50%;
}
</style>